import {slideToRight} from "../../animations/router.animations";
import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChildren, Injectable, ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../service/login.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {SessionStorageService} from "../../service/session/sessionStorage.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    partnerLogo = '../assets/images/logo.png';

    userForm: FormGroup;
    userFormSecond: FormGroup;
    userPinForm: FormGroup;

    phoneMask = PHONE_MASK.us;
    numOfDigits = 4;

    isPinPopupShown = false;
    isPromoCodeRequire = false;

    activeForm = null;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;
    @ViewChild('promoCode', {static: false}) promoCode: ElementRef;

    constructor(private authenticationService: AuthenticationService,
               private formBuilder: FormBuilder,
               private loginService: LoginService,
               private router: Router,
               private loaderService: LoaderService,
               private activatedRoute: ActivatedRoute,
               private sessionStorageService: SessionStorageService,
               private popupComponent: PopupComponent) {}
    ngOnInit() {
        this.authenticationService.logout();

        this.userForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });
        this.userFormSecond = this.formBuilder.group({
            phone: [null, Validators.required]
        });
        this.userPinForm = this.formBuilder.group({
            promoCode: [null],
            digits: this.formBuilder.array([])
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.userPinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    onPhoneSubmit(form?){
        if (form) {
            this.activeForm = form;
        }

        if (!this.activeForm || this.activeForm.controls.phone.invalid) {
            return;
        }

        const phone = this.activeForm.controls.phone.value;

        this.authenticationService.checkPhoneAndSendPin(phone).subscribe(data => {
            try {
                if (data.status) {
                    this.isPromoCodeRequire = !!data.promoCode;
                    // this.isPromoCodeRequire = true;
                    this.showPinForm();
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showPinForm() {
        this.isPinPopupShown = true;
        setTimeout(() => {
            if (this.isPromoCodeRequire) {
                this.promoCode.nativeElement.focus();
            } else {
                this.inputs.toArray()[0].nativeElement.focus();
            }
        });
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null);
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    onUserFormSubmit(){
        if ((this.userForm.invalid && this.userFormSecond.invalid) || this.userPinForm.invalid) {
            return;
        }

        const phone = this.activeForm.controls.phone.value;
        const promoCode = this.userPinForm.controls.promoCode.value;

        let pin = this.userPinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
            return
        }

        if (phone && pin && (pin.length === 4)) {
            this.authenticationService.login(phone, pin, promoCode).subscribe(data => {
                this.checkLoginSubmit(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkLoginSubmit(data) {
        try {
            if (data.status) {
                //this.popupComponent.showPopupSuccess({text: data.message});
                this.goToHomePage();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getAppLink() {
        return this.sessionStorageService.getAppLink() + '/senetpro?token=' + this.sessionStorageService.getUserToken();
    }

    goToHomePage() {
        setTimeout(() => {
            this.loaderService.show();
        });

        window.location.href = this.getAppLink();
    }

    resetPinInput() {
        (this.userPinForm.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.userPinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    onResend() {
        this.onPhoneSubmit();
        this.resetPinInput();
    }

    goBack() {
        this.isPinPopupShown = false;
        this.isPromoCodeRequire = false;
        this.resetPinInput();
        this.activeForm = null;
        this.userPinForm.controls.promoCode.reset();
    }

    closePopup($event) {
        this.goBack();
    }
}

