import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent},
            {path: '**', redirectTo: '', pathMatch: 'full'}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
