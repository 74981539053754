import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginService} from "./service/login.service";
import {Router} from "@angular/router";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {PopupComponent} from "./shared/popup/popup.component";
import {fromEvent} from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    isConnected = true;
    noInternetConnection = false;

    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private popupComponent: PopupComponent) {
        fromEvent(window, 'online').subscribe((resp: any) => {
            this.isConnected = true;
            this.noInternetConnection = false;
            this.popupComponent.closePopup();
        });

        fromEvent(window, 'offline').subscribe((resp: any) => {
            this.isConnected = false;
            this.noInternetConnection = true;
            this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false})
        });
    }

    ngOnInit() {
        if (this.isIos()) {
            document.body.classList.add('is-ios');
        }
        window.addEventListener('resize', this.appHeight);
        this.appHeight();
        document.body.classList.remove('is-blurred');
    }

    ngOnDestroy() {}

    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
}


