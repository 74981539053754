<div class="login" [class.is-blurred]="isPinPopupShown">
    <div class="login__inner">
        <div class="login__forms">
            <div class="login__tab">
                <div class="descr">
                    <div class="descr__block">
                        <h2 class="descr__title">Unlock Fun and Fortunes: Play, Win, and Own with Senet’s NFT-Powered Sweepstakes!</h2>
                        <p class="descr__text">Discover a whole new world of entertainment and opportunity with Senet, the ultimate destination for NFT enthusiasts and gaming aficionados. Our groundbreaking platform combines the excitement of NFTs, captivating online games, and the thrill of winning amazing prizes. Are you ready to embark on this extraordinary journey?</p>
                    </div>

                    <div class="descr__block">
                        <h2 class="descr__title descr__title--small">We’ll text you a one-time login code</h2>

                        <form [formGroup]="userForm" (ngSubmit)="onPhoneSubmit(userForm)" class="login__form">
                            <div class="phone-wrapper">
                                <div class="login__item">
                                    <input [mask]="phoneMask"
                                           formControlName="phone"
                                           placeholder="Enter Phone Number"
                                           [type]="'tel'"
                                           class="input input--uppercase"
                                           autocomplete="off">
                                </div>

                                <div class="login__btns">
                                    <button class="button button--small" [disabled]="!userForm.controls.phone.valid">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="descr__block">
                        <h2 class="descr__title">Thrilling Online Games</h2>
                        <p class="descr__text">Explore an array of captivating games like <span>Golden Dragon and River</span>, meticulously designed to keep you engaged and entertained. Whether you’re a casual gamer or a seasoned pro, our diverse collection has something for everyone.</p>
                    </div>

                    <div class="descr__block">
                        <h2 class="descr__title">Seamless Entry Process</h2>
                        <p class="descr__text">Getting started is as easy as 1-2-3! Simply input your phone number to initiate the sweepstakes process. Our user-friendly interface ensures a seamless and hassle-free experience, allowing you to focus on what matters most – having fun and winning big!</p>
                    </div>

                    <div class="descr__block">
                        <h2 class="descr__title">Contact Us</h2>
                        <p class="descr__text">Ready to dive into the excitement? Have questions or need assistance? Reach out to us at
                            <a href="mailto:peter@senetpro.com">peter@senetpro.com</a> and our dedicated team will be more than happy to assist you.</p>
                    </div>

                    <div class="descr__block">
                        <h2 class="descr__title descr__title--small">We’ll text you a one-time login code</h2>
                        <!--                <button class="button button&#45;&#45;small"-->
                        <!--                        (click)="goBack()"-->
                        <!--                        *ngIf="isPinShown">Back</button>-->

                        <form [formGroup]="userFormSecond" (ngSubmit)="onPhoneSubmit(userFormSecond)" class="login__form">
                            <div class="phone-wrapper">
                                <div class="login__item">
                                    <input [mask]="phoneMask"
                                           formControlName="phone"
                                           placeholder="Enter Phone Number"
                                           [type]="'tel'"
                                           class="input input--uppercase"
                                           autocomplete="off">
                                </div>

                                <div class="login__btns">
                                    <button class="button button--small" [disabled]="!userFormSecond.controls.phone.valid">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="login__footer">
                        <p class="text t-grey">Services are to be used for amusement purposes only. Customer acknowledges that the Services may be subject to U.S., state, country and municipal laws and regulations. Accordingly, Customer may not use of the Service except in full compliance with all applicable laws and regulations. Customer is responsible for understanding and using the Services in a manner that does not violate federal, state or local law.</p>
                        <div class="logos">
                            <img src="../../../assets/images/gd-logo.png" alt="Golden Dragon" class="logos__logo">
                            <img src="../../../assets/images/r-logo.png" alt="River" class="logos__logo">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup-wrapper [show]="isPinPopupShown" (onClose)="closePopup($event)">
    <div class="investments-popup__scrolled" id="scrolled-wrapper">

        <form [formGroup]="userPinForm" (ngSubmit)="onUserFormSubmit()" class="login__form">
            <div class="login__pin" *ngIf="isPromoCodeRequire">
                <p>
                    Enter your Promo Code
                </p>
            </div>

            <div class="login__item login__item--promo"
                 style="margin: 2rem auto 4rem; min-width: 40rem"
                 *ngIf="isPromoCodeRequire">
                <input formControlName="promoCode"
                       #promoCode
                       placeholder="Promo Code*"
                       [type]="'text'"
                       class="input"
                       autocomplete="off">
            </div>

            <div class="login__pin">
                <p>Enter the four-digit pin sent to your number</p>
                <div class="login__items" formArrayName="digits" style="margin-bottom: 4rem">
                    <input (keydown.enter)="onUserFormSubmit()" *ngFor="let field of userPinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i"  (keydown)="check(i, field, $event)"
                           mask="0"
                           class="login-input-small" type="tel">
                </div>


                <div class="text-center form-btn-submit" style="display: flex; justify-content: center; margin-top: 2rem">
                    <button (click)="onResend()" style="margin-right: 1rem" class="button button--no-border" type="button">Resend</button>

                    <button type="submit" style="margin-left: 1rem" class="button"
                            [disabled]="!userForm.valid || (isPromoCodeRequire && !userPinForm.controls.promoCode.value) || userPinForm.controls.digits.invalid">
                        Send
                    </button>
                </div>
            </div>
        </form>
    </div>
</app-popup-wrapper>
