import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopupComponent} from "./popup/popup.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {MatSelectModule} from "@angular/material";
import {DemoMaterialModule} from "../material.module";
import {PopupWrapperComponent} from "./popup-wrapper/popup-wrapper.component";

@NgModule({
    declarations: [
        PopupComponent,
        LoaderComponent,
        PopupWrapperComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        DemoMaterialModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        MatSelectModule,
    ],
    providers: [
    ],
    exports: [
        PopupComponent,
        LoaderComponent,
        PopupWrapperComponent
    ]
})
export class SharedModule { }
